

body,html {
  margin: 0;
  font-family: "BrandonText-Regular";
  background-color: #f8f9fb;
  color: #06080c
}

* {
  box-sizing: border-box
}

section {
  position: relative
}

input {
  outline: none
}

a {
  text-decoration: none;
  color: #4b83ff
}

button {
  border: none;
  outline: none;
  font-weight: bold
}

.container {
  max-width: 640px;
  width: 100%;
  padding: 0 20px
}

.btn-submit {
  bottom: 0;
  left: 0;
  height: 60px;
  width: 100%;
  pointer-events: auto;
  // background-color: $color--orange;
  background-image: linear-gradient(315deg, #303C6F 0%, #222E61 100%);
  color: white;

  &.fixed {
    position: fixed;
  }

  &.disabled {
    background-color: $color--disabled;
    color: $color--grey;
    pointer-events: none;
    background-image: none;
  }
}
.btn-next {
  background-color: #D0021B;
  border-radius: 100px;
  width: 100%;
  color: #fff;
  font-size: 16px;
  font-family: 'BrandonText-Bold';
  letter-spacing: 0.13px;
  padding: 16px;
  margin-top: 20px;
  display: block;
  text-align: center;
}
.btn-confirmation {
  height: 30px;
  width: 45%;
  margin-right: 5%;
  pointer-events: auto;
  background-image: linear-gradient(315deg, #303C6F 0%, #222E61 100%);
  color: white;

  &--outline {
    height: 30px;
    width: 45%;
    pointer-events: auto;
    border: 2px solid #222E61;
    background-color: white!important;
    color: #222E61;
  }
}

.popup-notif {
  // position: fixed;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8954a;
  // padding: 20px;
  padding-bottom: 80px;

  .content {
    position: relative;
    width: max-content;
    text-align: center;

    img {
      display: block;
      width: 100px;
      margin: auto;
      margin-bottom: 40px;
    }
    h3 {
      font-size: 20px;
      margin-bottom: 5px;
    }
    p {
      font-size: 14px;
      text-align: center;
      color: #0000009e;
      max-width: 280px;
      strong {
        color: #000000ff;
      }
    }
  }

  .btn-back {
    display: block;
    width: calc(100% - 40px);
    background-color: white;
    font-size: 14px;
    padding: 15px;
    border-radius: 10px;
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    color: black;
    text-align: center;
  }
}


// N
.btn-submit {
  bottom: 0;
  left: 0;
  height: 60px;
  width: 100%;
  pointer-events: auto;
  background-image: linear-gradient(315deg, #303C6F 0%, #222E61 100%);
  color: #fff;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center
}

.btn-submit.fixed {
  position: fixed
}

.btn-submit.disabled {
  background-color: #e5e5e5;
  color: #969aa2;
  pointer-events: none;
  background-image: none
}

.custom-field {
  position: relative;
  width: 100%;
  height: 52px;
  padding: 5px 10px;
  border: solid 1pt #e5e9f2;
  border-radius: 12px;
  font-size: 14px;
  font-weight: 400;
  font-family: "BrandonText-Regular"
}

input.search-field, select.select-field {
  position: relative;
  width: 25%;
  height: 36px;
  padding: 5px 10px;
  border: solid 1pt #e5e9f2;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 400;
  font-family: "BrandonText-Regular";
  &:not(:first-child) {
    left: 2%;
  }
}

select.language-select {
  background: transparent;
  border: 0;
  color: white;
}

@media only screen and (max-width: 767px) {
  input.search-field {
    width: 55%;
  }
  select.select-field {
    width: 45%;
  }
}