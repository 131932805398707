.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  background-color: rgba($color: #000000, $alpha: 0);
  pointer-events: none;
  transition-duration: 0.3s;

  &.active {
    pointer-events: auto;
    background-color: rgba($color: #000000, $alpha: 0.7);

    .content {
      transform: translateY(0%);
    }
  }

  .content {
    transition-duration: 0.3s;
    transform: translateY(100%);
    position: absolute;
    width: 100%;
    bottom: 0;
    padding: 20px;
    background-color: white;
    border-radius: 10px 10px 0 0;
    color: #06080c;
    .btn-close {
      position: absolute;
      right: 10px;
      top: -35px;
      background: transparent;
    }
    .title {
      font-size: 20px;
      font-weight: 500;
      font-family: 'BrandonText-Medium';
      margin-top: 5px;
      margin-bottom: 10px;
      display: inline-flex;
      .circle {
        height: 22.5px;
        width: 22.5px;
        border-radius: 50%;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
      }
    }

    #donutchart {
        position: relative;
    }
    
    #labelOverlay {
      font-family: 'BrandonText-Bold';
      font-size: 18px;
      position: absolute;
      top: 43%;
      left: 46.5%;
      text-align: center;
      cursor: default;
    }
    @media only screen and (max-width: 335px) {
      #labelOverlay {
        left: 33%;
      }
    }
    @media only screen and (min-width: 335px) and (max-width: 767px) {
      #labelOverlay {
        left: 36.5%;
      }
    }

    #labelOverlay p {
      line-height: 0.3;
      padding:0;
      margin: 8px;
    }
    
    #labelOverlay p.title {
      line-height: 0.5;
      font-size: 20pt;
    }
    
    #labelOverlay p.sub-title {
      line-height: 0.5;
      font-size: 12pt;
    }
  }
}
