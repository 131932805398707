.section {
  &-home {
    .header {
      width: 100%;
      position: relative;
      padding: 16px 20px;
      padding-bottom: 120px;
      // display: grid;
      // grid-template-columns: 160px 60px;
      // grid-column-gap: 91px;
      background-image: linear-gradient(315deg, #303C6F 0%, #222E61 30%);

      .title {
        font-size: 24px;
        font-family: 'BrandonText-Bold';
        letter-spacing: 0.48px;
        color: white;
        .sub-title {
          font-size: 15px;
          font-family: 'BrandonText-Regular';
          letter-spacing: 0;
        }
      }
      // @media only screen and (max-width: 335px) {
      //   .title {
      //     .sub-title {
      //       font-size: 15px;
      //     }
      //   }
      // }
      // @media only screen and (min-width: 335px) and (max-width: 767px) {
      //   .title {
      //     .sub-title {
      //       font-size: 15px;
      //     }
      //   }
      // }
      .language {
        position: relative;
        display: grid;
        grid-template-columns: auto auto;
        align-items: center;
        grid-column-gap: 16px;
      }
    }
    .body {
      position: relative;
      color: #333333;
      background-color: #FF444E;
      border-radius: 8px;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      margin: auto;
      top: -100px;
      min-width: 250px;
      max-width: 665px;
      margin: 0 auto;
      padding: 12px;
      &:nth-child(3n+1) {
        background-color: #3399FF;
      }
      &:nth-child(3n+2) {
        background-color: #FED766;
      }
      &:nth-child(3n+3) {
        background-color: #FF444E;
      }
      &:not(:first-child) {
        margin-top: 30px;
      }
      .input-container {
        display: block;
        width: 100%;
        margin-bottom: 15px;
        background-color: yellow;
      }
      .title {
        font-family: 'BrandonText-Medium';
        font-size: 20px;
        letter-spacing: 0.38px;
        margin-bottom: 2px;
        display: inline-flex;
        .circle {
          height: 22.5px;
          width: 22.5px;
          border-radius: 50%;
          background-repeat: no-repeat;
          background-position: center center;
          background-size: cover;
        }
      }
      .action {
        position: absolute;
        right: 3%;
      }
      .desc {
        font-family: 'BrandonText-Medium';
        width: 100%;
        display: flex;
        font-size: 13px;
        // margin-bottom: 16px;
      }
      .info {
        width: 100%;
        display: flex;
        button {
          font-family: 'BrandonText-Medium';
          border: none;
          outline: none;
          background: none;
          color: white;
          font-size: 1rem;
          font-weight: 400;
          flex-direction: row;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
      }
    }

    @media only screen and (max-width: 335px) {
      .body{
        max-width: 335px;
        margin: 0 20px;
        &:not(:first-child) {
          margin-top: 20px;
        }
      }
      h1 {
        font-size: 5.5vw;
      }
      h2 {
        font-size: 3.0vh;
      }
      h3 {
        font-size: 2.5vh;
      }
    }

    @media only screen and (max-width: 767px) {
      .body{
        margin: 0 20px;
      }
      h1 {
        font-size: 5.5vw;
      }
      h2 {
        font-size: 2.5vh;
      }
      h3 {
        font-size: 2.0vh;
      }
    }

  }
}
